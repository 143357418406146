var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"content-header"},[_c('b-col',{staticClass:"content-header-left mb-2",attrs:{"cols":"12","md":"12"}},[_c('b-row',{staticClass:"breadcrumbs-top"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"custom-header-title float-left pr-1 mb-0"},[_vm._v(" Editing Contact Information ")])])],1)],1)],1),_c('b-alert',{attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_c('strong',[_vm._v("For SG-Nirvana")]),_vm._v(" This is the control panel where administrators can edit the content for the customer-facing mobile app.")])])]),_c('b-card',[_c('validation-observer',{ref:"appSettingsForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"md-12"}},[_c('b-form-group',{attrs:{"label":"Operating Hours*","label-for":"h-operating-hours","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Operating Hours","vid":"operatingHours","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-operating-hours","placeholder":"E.g. Monday – Friday 8am to 4pm","state":(errors.length > 0 || _vm.operatingHoursValidation) ? false : null,"name":"operatingHours"},on:{"input":function($event){_vm.operatingHoursValidation == true ? _vm.operatingHoursValidation = false : null}},model:{value:(_vm.operatingHours),callback:function ($$v) {_vm.operatingHours=$$v},expression:"operatingHours"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.operatingHoursValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.operatingHoursError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"md-12"}},[_c('b-form-group',{attrs:{"label":"Contact Number*","label-for":"h-contact-number","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Contact Number","vid":"contactNumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-contact-number","placeholder":"Contact Number","state":(errors.length > 0 || _vm.contactNumberValidation) ? false : null,"name":"contactNumber"},on:{"input":function($event){_vm.contactNumberValidation == true ? _vm.contactNumberValidation = false : null}},model:{value:(_vm.contactNumber),callback:function ($$v) {_vm.contactNumber=$$v},expression:"contactNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.contactNumberValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.contactNumberError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"md-12"}},[_c('b-form-group',{attrs:{"label":"Email Contact*","label-for":"h-contact-email","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"Email Contact","vid":"contactEmail","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-contact-email","placeholder":"E.g. hello@nirvana.com.sg","state":(errors.length > 0 || _vm.contactEmailValidation) ? false : null,"name":"contactEmail"},on:{"input":function($event){_vm.contactEmailValidation == true ? _vm.contactEmailValidation = false : null}},model:{value:(_vm.contactEmail),callback:function ($$v) {_vm.contactEmail=$$v},expression:"contactEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.contactEmailValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.contactEmailError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Address*","label-for":"event-address","label-cols-md":"2"}},[_c('validation-provider',{attrs:{"name":"address","vid":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"event-address","placeholder":"Add address","rows":"3","name":"address"},on:{"input":function($event){_vm.addressValidation == true ? _vm.addressValidation = false : null}},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.addressValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.addressError)+" ")]):_vm._e()]}}],null,true)})],1)],1)],1),_c('b-nav',{staticClass:"wrap-border save-nav"},[_c('li',{staticClass:"nav-item ml-auto"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"flat-primary","to":{ name: 'app-contact-information-index' }}},[_vm._v(" Cancel ")])],1),_c('li',{staticClass:"nav-item mr-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"success","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Save Contact Information")])],1)],1)])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }